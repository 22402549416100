<template>
  <div class="col-12 col-xl-5">
    <div class="border border-color-vio block rounded shadow p-3 mb-5 bg-white rounded">
      <h4 class="text-center content-blocks-title"><strong>{{ Block_Title }}</strong></h4>
      <hr/>

      <form @submit.prevent="submitPM">
        <div class="form-group px-3 py-3">
          <div class="input-group mb-3">
            <input type="text"
                   class="form-control input-conseiller-send-pm border border-color-vio shadow"
                   placeholder="Objet : " aria-label="Username"
                   aria-describedby="basic-addon1" v-model="pm.title" minlength="4" maxlength="200">
          </div>
        </div>
        <div class="form-group px-3">
          <div class="input-group mb-3">
            <textarea v-model="pm.content"
              class="form-control input-conseiller-send-pm-txt-area border border-color-vio block shadow"
              id="exampleFormControlTextarea1" rows="3" placeholder="Mon message :" maxlength="2000" minlength="5"></textarea>
          </div>
        </div>
        <div class="alert alert-danger" v-bind:class="{ hide_element : isErrorModalHidden }" role="alert">
          Merci de remplir les champs obligatoires !
        </div>
        <div class="text-center">
          <button type="submit"
            class="button-warning-cus to-validate-with-frost"
            :disabled="submitStatus === 'PENDING'">{{ Send_PM_Label }}
            <div class="arrow-white"/>
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import {computed} from "vue";
import {mapActions, useStore} from "vuex";
import useVuelidate from '@vuelidate/core'
import {maxLength, minLength, required} from '@vuelidate/validators'
import emitter from '../../events/emitter';

export default {
  name: 'SendPM',
  setup() {
    const store = useStore();

    const getAdvisor = computed(() => store.getters[`user/getAdvisor`]);

    return {
      v$: useVuelidate(),
      getAdvisor
    }
  },
  data() {
    return {
      Block_Title: "ENVOYER UN MESSAGE",
      Send_PM_Label: "Envoyer",
      pm: {
        title: "",
        content: ""
      },
      submitStatus: null,
      isErrorModalHidden: true
    }
  },
  validations() {
    return {
      pm: {
        title: {
          required,
          minLength: minLength(4),
          maxLength: maxLength(200)
        },
        content: {
          required,
          minLength: minLength(5),
          maxLength: maxLength(2000)
        }
      }
    }
  },
  methods: {
    ...mapActions('message', ['sendMesage']),
    submitPM() {
      this.v$.$validate()
      this.v$.$touch()
      if (this.v$.$invalid) {
        this.submitStatus = 'ERROR'
        this.handleIsErrorModalDisplay()
      } else {
        // do your submit logic here
        this.submitStatus = 'PENDING'
        this.doPostPM();
      }
    },
    async doPostPM() {
      await this.sendMesage({
        message: this.pm.title + ' : ' + this.pm.content,
        contactId: this.getAdvisor ? this.getAdvisor.id : null
      })
          .then(response => {
            if (response == 'OK') {
              this.pm.title = '';
              this.pm.content = '';
              emitter.emit('messages-refresh');
            }
          })
          .catch(error => {
            console.error('There was an error!', error);
          }).finally(() => this.submitStatus = null)
    },
    handleIsErrorModalDisplay() {
      this.isErrorModalHidden = false;
      setTimeout(() => {
        this.isErrorModalHidden = true;
      }, 3000);
    },
  },
  watch: {
    pm: {
      handler: function(value) {
        if (value && value.content) {
          value.content = value.content.replace('"', '”');
        }
      },
      deep: true
    }
  }
}
</script>

<style scoped>
.input-conseiller-send-pm {
  border-radius: 15px;
  height: 50px;
}

.input-conseiller-send-pm::placeholder {
  color: #2B2E86;
}

.input-conseiller-send-pm-txt-area {
  border-radius: 15px;
  height: 400px;
  color: #2B2E86;
}

.input-conseiller-send-pm-txt-area::placeholder {
  color: #2B2E86;
}

textarea {
  padding: 12px;
}
</style>
